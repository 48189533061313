angular.module("componentes").directive("textEditor", function () {
	"use strict";

	return {
		scope: true,
		controller: "TextEditorCtrl",
		controllerAs: "ctrl",
		//transclude: true,
		replace: true,
		templateUrl: "componentes/angular-material/custom/textEditor/textEditor_template.html",
		bindToController: {
			label: "@",
			field: "=",
			onBlur: "&?",
			isRequired: "="
		}
	};
});
