angular.module("componentes").directive("textFieldRequired", [
	"$timeout",
	function ($timeout) {
		"use strict";
		return {
			scope: true,
			controller: "TextFieldRequiredCtrl",
			controllerAs: "ctrl",
			replace: true,
			templateUrl: "componentes/angular-material/custom/fields/textFieldRequired/textFieldRequired_template.html",
			bindToController: {
				label: "@",
				name: "@",
				isDisabled: "=",
				type: "@",
				field: "=",
				required: "@",
				error: "=",
				requiredMessage: "@",
				focus: "@",
				maxlength: "@?"
			}
		};
	}
]);
