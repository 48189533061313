angular.module("componentes").factory("httpService", [
	"$http",
	"$q",
	"$rootScope",
	function ($http, $q, $rootScope) {
		"use strict";

		var JSONConversionIgnoreList = [];

		var _ignorePropertiesOnJSONConversion = function (properties) {
			JSONConversionIgnoreList = JSONConversionIgnoreList.concat(properties);
		};

		var _getListFromService = function getListFromService(serviceFunction, filterDTO, listName) {
			var deferred = null;

			if (!listName) {
				listName = "list";
			}

			deferred = $q.defer();
			serviceFunction(filterDTO).then(
				function (response) {
					var data = _getJSONFromResponse(response);
					var success = typeof data.success === "string" ? JSON.parse(data.success) : data.success;
					if (success) {
						deferred.resolve(_toList(data.content[listName]));
					} else {
						_showFailMessage(data);
						deferred.reject();
					}
				},
				function (err) {
					_showFailMessage();
					deferred.reject(err);
				}
			);

			return deferred.promise;
		};

		var _getJSONFromResponse = function (response) {
			if (typeof response.data === "string") {
				return response.data;
			}

			if (response.data && typeof response.data.d === "string") {
				return JSON.parse(response.data.d);
			}

			if (response.data || response.data === 0) return response.data;

			return response;
		};

		var _showFailMessage = function (response, useServerMessageAsFailMessage) {
			if (!response) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_CONNECTION_ERROR);
				return;
			}

			if (typeof response === "string") {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels[response]);
			} else if (useServerMessageAsFailMessage) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, response.Message || response.message);
			} else if (response.Code || response.code) {
				// new apis response format
				showErrorMessageBasedOnResponseCode(response);
			} else if (response.errorCode) {
				// old apis response format
				showErrorMessageBasedOnResponseErrorCode(response);
			} else {
				showErrorMessageBasedOnResponseMessage(response);
			}
		};

		var _showValidationFailMessage = function (messageKey) {
			if (messageKey) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels[messageKey]);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
			}
		};

		var _getDTOFromServiceById = function (serviceFunction, id) {
			var deferred = null;

			deferred = $q.defer();
			serviceFunction(id).then(
				function (response) {
					var data = _getJSONFromResponse(response);
					var success = typeof data.success === "string" ? JSON.parse(data.success) : data.success;
					if (success) {
						var dto = _convertJSONMembers(data.content);
						deferred.resolve(dto);
					} else {
						_showFailMessage(data);
						deferred.reject(data);
					}
				},
				function (err) {
					_showFailMessage();
					deferred.reject(err);
				}
			);

			return deferred.promise;
		};

		var _postDTOToService = function (serviceFunction, dto, deferred, ignoreServerError) {
			if (!deferred) {
				deferred = $q.defer();
			}
			serviceFunction(dto).then(
				function (response) {
					var data = _getJSONFromResponse(response);
					var success = typeof data.success === "string" ? JSON.parse(data.success) : data.success;
					if (success) {
						if (data.content) {
							var content = _convertJSONMembers(data.content);
						}
						deferred.resolve({ content: content });
					} else {
						deferred.reject(response.data || response);
						if (!ignoreServerError) {
							_showFailMessage(data);
						}
					}
				},
				function (error) {
					deferred.reject(error);
					_showFailMessage(error);
				}
			);

			return deferred.promise;
		};

		var _convertJSONMembers = function (dto) {
			for (var property in dto) {
				var ignoreProperty = JSONConversionIgnoreList.indexOf(property) >= 0;

				if (dto.hasOwnProperty(property)) {
					if (property === "@type") {
						delete dto["@type"];
					} else if (dto[property] instanceof Object && !ignoreProperty) {
						var value = _convertJSONMembers(dto[property]);
						//if(typeof(value) !== "number")
						dto[property] = value;
					} else if (!ignoreProperty) {
						try {
							dto[property] = JSON.parse(dto[property]);
						} catch (ex) {}
					}
				}
			}

			Vgr.date.fromISOStringsToDate(dto);

			return dto;
		};

		var _toList = function (obj) {
			var outputList = [];
			if (obj === null || typeof obj === "undefined") {
				outputList = [];
			} else if (!Array.isArray(obj)) {
				outputList.push(obj);
			} else {
				outputList = obj;
			}

			outputList.forEach(function (item) {
				item = _convertJSONMembers(item);
			});

			return outputList;
		};

		var _toListAndCount = function (obj, count) {
			return {
				list: _toList(obj),
				count: count
			};
		};

		var _postDTOToServiceV2 = function (
			serviceFunction,
			dto,
			ignoreDefaultErrorHandling,
			useServerMessageAsFailMessage
		) {
			var deferred = $q.defer();
			serviceFunction(dto).then(
				function (response) {
					var data = response.data;
					deferred.resolve(data);
				},
				function (error) {
					if (!ignoreDefaultErrorHandling) {
						errorV2(error, false, useServerMessageAsFailMessage);
					}
					deferred.reject(error);
				}
			);

			return deferred.promise;
		};

		var _deleteDTOToServiceV2 = function (serviceFunction, dto) {
			var deferred = $q.defer();
			serviceFunction(dto).then(
				function (response) {
					var data = response.data;
					deferred.resolve(data);
				},
				function (error) {
					errorV2(error);
					deferred.reject(error);
				}
			);

			return deferred.promise;
		};

		var _getListFromServiceV2 = function getListFromServiceV2(serviceFunction, filterDTO, listName) {
			if (!listName) {
				listName = "list";
			}

			var deferred = $q.defer();
			serviceFunction(filterDTO).then(
				function (response) {
					var data = response.data;
					deferred.resolve(_toList(data[listName]));
				},
				function (error) {
					errorV2(error);
					deferred.reject(error);
				}
			);

			return deferred.promise;
		};

		var _getListFromServiceCore = function getListFromServiceCore(serviceFunction, filterDTO, forcePascal) {
			var deferred = $q.defer();
			serviceFunction(filterDTO).then(
				function (response) {
					var count = null;
					if (response.headers) {
						count = response.headers("X-Total-Count");
					} else if (response.data) {
						count = response.data.length;
					}

					var data =
						forcePascal && count > 0
							? response.data.map(function (d) {
									return toPascal(d);
							  })
							: response.data;
					deferred.resolve(_toListAndCount(data, count));
				},
				function (error) {
					errorV2(error);
					deferred.reject(error);
				}
			);

			return deferred.promise;
		};

		function toPascal(o) {
			var newO, origKey, newKey, value;
			if (o instanceof Array) {
				return o.map(function (value) {
					if (typeof value === "object") {
						value = toPascal(value);
					}
					return value;
				});
			} else {
				newO = {};
				for (origKey in o) {
					if (o.hasOwnProperty(origKey)) {
						newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
						value = o[origKey];
						if (value instanceof Array || (value !== null && value.constructor === Object)) {
							value = toPascal(value);
						}
						newO[newKey] = value;
					}
				}
			}
			return newO;
		}

		function errorV2(error, isLocalServerCall, useServerMessageAsFailMessage) {
			if (error.status == 500) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					isLocalServerCall ? $rootScope.labels.LOCAL_SERVER_ERROR : $rootScope.labels.SERVER_CONNECTION_ERROR
				);
			} else if (error.status == Vgr.constants.httpStatus.Forbidden) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_PERMISSION_GENERAL_ERROR);
			} else if (error.status == Vgr.constants.httpStatus.Notfound) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NOT_FOUND_404);
			} else if (error.data && Array.isArray(error.data)) {
				_showFailMessage(error.data[0], useServerMessageAsFailMessage);
			}
		}

		var _getDTOFromServiceV2Call = function (serviceFunction, dto, isLocalServerCall, ignoreDefaultErrorHandling) {
			var deferred = $q.defer();
			serviceFunction(dto).then(
				function (response) {
					var data = response.data;
					deferred.resolve(data);
				},
				function (error) {
					if (!ignoreDefaultErrorHandling) {
						errorV2(error, isLocalServerCall);
					}
					deferred.reject(error);
				}
			);

			return deferred.promise;
		};

		var _getFromLocalServer = function (serviceFunction, dto) {
			return _getDTOFromServiceV2Call(serviceFunction, dto, true);
		};

		var _getDTOFromServiceV2 = function (serviceFunction, dto, ignoreDefaultErrorHandling) {
			return _getDTOFromServiceV2Call(serviceFunction, dto, false, ignoreDefaultErrorHandling);
		};

		return {
			getListFromService: _getListFromService,
			getDTOFromServiceById: _getDTOFromServiceById,
			postDTOToService: _postDTOToService,
			showValidationFailMessage: _showValidationFailMessage,
			ignorePropertiesOnJSONConversion: _ignorePropertiesOnJSONConversion,
			postDTOToServiceV2: _postDTOToServiceV2,
			deleteDTOToServiceV2: _deleteDTOToServiceV2,
			getListFromServiceV2: _getListFromServiceV2,
			getDTOFromServiceV2: _getDTOFromServiceV2,
			getListFromServiceCore: _getListFromServiceCore,
			getFromLocalServer: _getFromLocalServer
		};

		function showErrorMessageBasedOnResponseCode(response) {
			const code = response.Code || response.code;
			const message = response.Message || response.message;
			const errorLabel = $rootScope.labels[code];
			if (errorLabel) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, errorLabel);
			} else if (message) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, message);
			}
		}

		function showErrorMessageBasedOnResponseErrorCode(response) {
			if (response.message) {
				var errorLabel = $rootScope.labels[response.errorCode];
				var errorMessage = errorLabel
					? errorLabel.replace("[PARAM]", response.message.trim())
					: response.message.trim();
				if (errorLabel) $rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, errorMessage);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels[response.errorCode]);
			}
		}

		function showErrorMessageBasedOnResponseMessage(response) {
			if (response.Message) {
				// new apis response format, there is no error code in response, but message can be shown to user
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, response.Message);
			} else if (response.message) {
				// old apis response format, internal exception message, to be ignored
				console.error(response.message);
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_ERROR);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_ERROR);
			}
		}
	}
]);
