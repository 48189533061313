angular.module("componentes").controller("TextEditorCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		$scope.$watch("ctrl.field", function (newVal) {
			$scope.show = true;
		});
	}
]);
