angular.module("componentes").controller("GridCtrl", [
	"$scope",
	"$state",
	"$stateParams",
	"$rootScope",
	"$mdConstant",
	"localStorageService",
	"dialogService",
	"clientService",
	"$mdMedia",
	function (
		$scope,
		$state,
		$stateParams,
		$rootScope,
		$mdConstant,
		localStorageService,
		dialogService,
		clientService,
		$mdMedia
	) {
		"use strict";

		var permissionActionTypes = Vgr.enumerations.systemPermissions.permissionActionTypes;
		var typingTimer;
		var doneTypingInterval = 1000;
		var map = {};

		$scope.generalStatus = Vgr.constants.generalStatus;

		$scope.tableId = Vgr.util.uuid();

		this.$onInit = function () {
			$scope.showGridToolbar =
				$scope.ctrl.options.showGridToolbar &&
				(hasPermission(permissionActionTypes.Create) || hasPermission(permissionActionTypes.Delete));

			$scope.configStorage = {
				getFromLocalStorageService: function (key) {
					return localStorageService.get($scope.ctrl.options.title + "." + key);
				},
				setLocalStorageService: function (key, value) {
					localStorageService.set($scope.ctrl.options.title + "." + key, value);
				},
				getForAll: function (key) {
					return localStorageService.get(key);
				},
				setForAll: function (key, value) {
					localStorageService.set(key, value);
				}
			};

			$scope.showTextChipsField = $state.includes("storageList");
			$scope.isSupplier = clientService.isSupplier();
			$scope.decimals = clientService.getDecimalPlaces();
			if ($stateParams.textFilter) {
				$scope.shouldFilterByStateParam = true;
			}
			if ($stateParams.tipo) {
				localStorageService.set($scope.ctrl.options.title + "." + "filter.searchText", null);
			}

			$scope.ctrl.options.selected = [];
			$scope.filtering = false;
			$scope.isDeleting = false;

			if (!$scope.ctrl.options.query) {
				$scope.ctrl.options.query = {
					order: "ID",
					limit: Number.MAX_VALUE,
					page: 1,
					removePagination: true
				};
			}

			if (!$scope.colOrder) {
				$scope.updateCurrentColumn($scope.ctrl.options.query.order);
				var gridSortColumn = $scope.configStorage.getFromLocalStorageService("gridSortColumn");
				if ($scope.ctrl.options && gridSortColumn) {
					$scope.colOrder.column = gridSortColumn;
					$scope.colOrder.order = $scope.configStorage.getFromLocalStorageService("gridSortOrder");

					$scope.ctrl.options.query.order = $scope.colOrder.column;
					if ($scope.colOrder.order == "DESC") {
						$scope.ctrl.options.query.order = "-" + $scope.ctrl.options.query.order;
					}
				}
			}

			$scope.permissions = {
				create: true,
				delete: true,
				print: true,
				view: true
			};

			$scope.reportFilter = {};

			$scope.ctrl.options.getSelectedTagsFilter = function () {
				return $scope.selectedTagsFilter;
			};
			$scope.ctrl.options.getSelectedTextFilter = function () {
				return $scope.textTagsFilter;
			};

			$scope.ctrl.options.gridFilter = {
				text: "",
				options: {
					debounce: 500
				},
				show: false
			};

			$scope.ctrl.options.updateLayout = $scope.updateInnerTableHeightsIfNecessary;
			$scope.ctrl.options.deleteFromOptions = $scope.delete;

			$scope.getDescendantProp = Vgr.util.getDescendantProp;

			$scope.ctrl.options.processGroupBys = function () {
				var options = this;
				var groupedColumns = options.columnDefinitions.filter(function (columnDefinition) {
					return columnDefinition.groupBy && columnDefinition.textField;
				});

				groupedColumns.forEach(function (groupedColumn) {
					$scope.bindFields(options.data, groupedColumn.textField, groupedColumn.groupBy);
				});
			};

			$scope.keys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA, $mdConstant.KEY_CODE.TAB];
			$scope.showCheckBoxes = null;

			Object.defineProperty($scope, "pageLimit", {
				get: function () {
					return $scope.configStorage.getForAll("grid.pageLimit");
				},
				set: function (value) {
					$scope.configStorage.setForAll("grid.pageLimit", value);
				},
				enumerable: true
			});

			if (!$scope.pageLimit) {
				$scope.pageLimit = 20;
			}

			$scope.selectedTagsFilter = [];

			$scope.textTagsFilter = [];
			if (
				$scope.ctrl.options.gridFilter.text ||
				($scope.hasDateFilter() && $scope.ctrl.options.dateFilterInfo.filter.dateId > 0) ||
				$scope.selectedTagsFilter.length > 0
			) {
				$scope.filtering = true;
			}
			$scope.reloadList();
		};

		function hasPermission(actionType) {
			var hasPermissionMethod = $scope.ctrl.options.hasPermission;
			if (!hasPermissionMethod) {
				return true;
			}

			return hasPermissionMethod(actionType);
		}

		$scope.updateCurrentColumn = function (p_column) {
			if (!$scope.colOrder) {
				$scope.colOrder = {};
			}

			if (!p_column) {
				return;
			}

			var column = p_column.indexOf("-") > -1 ? p_column.replace("-", "") : p_column;
			var order = p_column.indexOf("-") > -1 ? "DESC" : "ASC";
			$scope.colOrder.column = column;
			$scope.colOrder.order = order;

			if ($scope.ctrl && $scope.ctrl.options) {
				$scope.configStorage.setLocalStorageService("gridSortColumn", $scope.colOrder.column);
				$scope.configStorage.setLocalStorageService("gridSortOrder", $scope.colOrder.order);
			}
		};

		$scope.onFilter = function () {
			if (typingTimer) {
				clearTimeout(typingTimer);
			}
			typingTimer = setTimeout(doneTyping, doneTypingInterval);
		};

		$scope.addTextChip = function () {
			var textFilter = "";
			for (var i = 0; i < $scope.textTagsFilter.length; i++) {
				textFilter += $scope.textTagsFilter[i];
				if (i + 1 < $scope.textTagsFilter.length) {
					textFilter += ";";
				}
			}
			$scope.ctrl.options.gridFilter.text = textFilter;
			doneTyping();
		};

		$scope.addTextChipFromNavBar = function (filter) {
			var textFilter = "";
			for (var i = 0; i < filter.length; i++) {
				textFilter += filter[i];
				if (i + 1 < filter.length) {
					textFilter += ";";
				}
			}
			$scope.ctrl.options.gridFilter.text = textFilter;
			doneTyping();
		};

		$scope.onDateFilterSelectChange = function (type) {
			$scope.ctrl.options.dateFilterInfo.filter.dateId = type;
			if ($scope.hasDateFilter()) {
				$scope.reloadList();
			}
		};

		$scope.onCustomPeriodDateFilterButtonClick = function () {
			if ($scope.hasDateFilter()) {
				$scope.reloadList();
			} else if ($scope.ctrl.options.dateFilterInfo.filter.dateId == 3) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels["ERR-RES-0001"]);
			}
		};

		$scope.hasDateFilter = function () {
			return (
				$scope.ctrl.options.dateFilterInfo &&
				$scope.ctrl.options.dateFilterInfo.filter &&
				$scope.ctrl.options.dateFilterInfo.filter.dateId !== null &&
				($scope.ctrl.options.dateFilterInfo.filter.dateId != 3 ||
					($scope.ctrl.options.dateFilterInfo.filter.beginDate !== null &&
						$scope.ctrl.options.dateFilterInfo.filter.endDate !== null))
			);
		};

		$scope.hasClientDateFilter = function () {
			return $scope.ctrl.options.dateFilterInfo;
		};

		function doneTyping() {
			$scope.reportFilter.query = $scope.ctrl.options.gridFilter.text;
			$scope.updateInnerTableHeightsIfNecessary();
			if ($scope.ctrl.options.onFilter) {
				$scope.reloadList();
			}
		}

		$scope.$watch("selectedTagsFilter.length", function (newValue, oldValue) {
			if (newValue !== oldValue) {
				$scope.reloadList();
			}
		});

		$rootScope.$watch("selectedTagsFilter.length", function (newValue, oldValue) {
			if (newValue !== oldValue) {
				$scope.selectedTagsFilter = $rootScope.selectedTagsFilter;
			}
		});

		$scope.onReorder = function (column) {
			$scope.updateCurrentColumn(column);
			$scope.reportFilter.column = $scope.colOrder.column;
			$scope.reportFilter.order = $scope.colOrder.order;
			$scope.updateInnerTableHeightsIfNecessary();
			if ($scope.ctrl.options.query.onReorder) {
				if ($scope.hasDateFilter()) {
					$scope.ctrl.options.query.onReorder(
						$scope.ctrl.options.gridFilter.texts
							? $scope.ctrl.options.gridFilter.texts
							: $scope.ctrl.options.gridFilter.text,
						$scope.colOrder.column,
						$scope.colOrder.order,
						$scope.selectedTagsFilter,
						$scope.ctrl.options.dateFilterInfo.filter
					);
				} else {
					$scope.ctrl.options.query.onReorder(
						$scope.ctrl.options.gridFilter.texts
							? $scope.ctrl.options.gridFilter.texts
							: $scope.ctrl.options.gridFilter.text,
						$scope.colOrder.column,
						$scope.colOrder.order,
						$scope.selectedTagsFilter
					);
				}
			}
		};

		$scope.onPaginate = function (page, limit) {
			$scope.pageLimit = limit;
			$scope.updateInnerTableHeightsIfNecessary();
			if ($scope.ctrl.options.query.onPaginate) {
				if ($scope.hasDateFilter()) {
					$scope.ctrl.options.query.onPaginate(
						page,
						limit,
						$scope.ctrl.options.gridFilter.texts
							? $scope.ctrl.options.gridFilter.texts
							: $scope.ctrl.options.gridFilter.text,
						$scope.colOrder.column,
						$scope.colOrder.order,
						$scope.selectedTagsFilter,
						$scope.ctrl.options.dateFilterInfo.filter
					);
				} else {
					$scope.ctrl.options.query.onPaginate(
						page,
						limit,
						$scope.ctrl.options.gridFilter.texts
							? $scope.ctrl.options.gridFilter.texts
							: $scope.ctrl.options.gridFilter.text,
						$scope.colOrder.column,
						$scope.colOrder.order,
						$scope.selectedTagsFilter
					);
				}
			}
		};

		$scope.onClickFilter = function () {
			$scope.filtering = !$scope.filtering;
			if ($scope.filtering) {
				setTimeout(function () {
					$("#searchInput").focus();
				}, 200);
			} else {
				$scope.ctrl.options.gridFilter.text = "";
				$scope.selectedTagsFilter = [];
				$scope.textTagsFilter = [];
				if ($scope.ctrl.options.dateFilterInfo && $scope.ctrl.options.dateFilterInfo.filter) {
					$scope.ctrl.options.dateFilterInfo.filter.dateId = 0;
					$scope.ctrl.options.dateFilterInfo.filter.beginDate = null;
					$scope.ctrl.options.dateFilterInfo.filter.endDate = null;
				}

				if ($scope.ctrl.options.onFilter && $scope.ctrl.options.query.serverPagination) {
					$scope.ctrl.options.onFilter(
						$scope.ctrl.options.gridFilter.text,
						$scope.colOrder.column,
						$scope.colOrder.order
					);
				} else {
					$scope.getList();
				}
			}
		};

		$scope.removeFilter = function () {
			$scope.ctrl.options.gridFilter.text = "";
			$scope.ctrl.options.gridFilter.show = false;
		};

		$scope.isLoading = function () {
			return !$scope.ctrl.options.hasData && !$scope.ctrl.options.errorLoading;
		};

		$scope.reloadList = function () {
			if (!$scope.ctrl.options.query || !$scope.ctrl.options.query.serverPagination) {
				$scope.getList(true);
			} else {
				if ($scope.hasDateFilter()) {
					$scope.ctrl.options.onFilter(
						$scope.ctrl.options.gridFilter.texts
							? $scope.ctrl.options.gridFilter.texts
							: $scope.ctrl.options.gridFilter.text,
						$scope.colOrder.column,
						$scope.colOrder.order,
						$scope.selectedTagsFilter,
						$scope.ctrl.options.dateFilterInfo.filter
					);
				} else if ($scope.ctrl.options.onFilter) {
					$scope.ctrl.options.onFilter(
						$scope.ctrl.options.gridFilter.texts
							? $scope.ctrl.options.gridFilter.texts
							: $scope.ctrl.options.gridFilter.text,
						$scope.colOrder.column,
						$scope.colOrder.order,
						$scope.selectedTagsFilter
					);
				}
			}
		};

		function setTextTagsFilterByText() {
			var parts = $scope.ctrl.options.gridFilter.text.split(";");
			for (var i = 0; i < parts.length; i++) {
				$scope.textTagsFilter.push(parts[i]);
			}
			$rootScope.textTagsFilter = $scope.textTagsFilter;
		}

		$scope.getList = function () {
			if ($scope.shouldFilterByStateParam) {
				$scope.ctrl.options.gridFilter.text = $stateParams.textFilter;
				$scope.shouldFilterByStateParam = false;
				setTextTagsFilterByText();
				$scope.reloadList();
				return;
			}
			if (
				$scope.ctrl.options &&
				$scope.ctrl.options.gridFilter &&
				$scope.ctrl.options.gridFilter.text &&
				$scope.textTagsFilter.length === 0
			) {
				setTextTagsFilterByText();
			}
			if (
				$scope.ctrl.options &&
				$scope.ctrl.options.data &&
				$scope.ctrl.options.query &&
				$scope.ctrl.options.query.page <= 0
			) {
				$scope.ctrl.options.query.page = 1;
			}
			if ($scope.ctrl.options.removeSearch) {
				$scope.filteredList = $scope.ctrl.options.data;
				return $scope.filteredList;
			}

			if ($scope.ctrl.options.onFilter && $scope.ctrl.options.query.serverPagination) {
				$scope.filteredList = $scope.ctrl.options.data;
			} else {
				$scope.filteredList = Vgr.filters.filterBy(
					$scope.ctrl.options.data,
					$scope.ctrl.options.gridFilter.text,
					$scope.ctrl.options.filterFields,
					false,
					$scope.ctrl.options.filterSubFields
				);
				if ($scope.ctrl.options.gridFilter.status) {
					$scope.filteredList = Vgr.filters.filterBySome(
						$scope.filteredList,
						$scope.ctrl.options.gridFilter.status,
						[$scope.ctrl.options.statusProperty],
						true
					);
				}
				if ($scope.hasClientDateFilter()) {
					var filterField = [];
					filterField.push($scope.ctrl.options.dateToFilter); //Change this.
					$scope.filteredList = Vgr.filters.filterByDate(
						$scope.filteredList,
						$scope.ctrl.options.dateFilterInfo.filter,
						filterField
					);
				}
				if ($scope.selectedTagsFilter && $scope.selectedTagsFilter.length > 0) {
					var filterField = [];
					filterField.push("Tags");
					$scope.selectedTagsFilter.forEach(function (tagFilter) {
						$scope.filteredList = Vgr.filters.filterBy(
							$scope.filteredList,
							tagFilter.Name,
							filterField,
							false,
							$scope.ctrl.options.filterSubFields
						);
					});
				}
			}

			if ($scope.ctrl.options.query.page <= 0) {
				$scope.ctrl.options.query.page = 1;
			}

			return $scope.filteredList;
		};

		$scope.getTrStyle = function (status) {
			if (!status) {
				return "";
			}
			if (!$scope.ctrl.options.columnDefinitions[0].status) {
				return;
			}
			for (var i = 0; i < $scope.ctrl.options.columnDefinitions[0].status.length; ++i) {
				var statusDefinition = $scope.ctrl.options.columnDefinitions[0].status[i];
				if (statusDefinition.name == status) {
					return {
						"border-left": "3px solid " + statusDefinition.color
					};
				}
			}
		};

		$scope.$watch("ctrl.options.hasData", function (newValue) {
			if (newValue) {
				$scope.updateInnerTableHeightsIfNecessary();
			}
		});

		$scope.updateInnerTableHeightsIfNecessary = function () {
			setTimeout(function () {
				if (!$(".md-table").is(":visible")) {
					return;
				}

				$(".md-table").each(function () {
					$(this)
						.find("tbody tr")
						.each(function () {
							var colSize = $(this).find(".inner-md-table").length;
							if (colSize > 0) {
								var maxRows = 0;
								var attention = false;
								var warn = false;
								var alert = false;
								for (var z = 0; z < colSize; ++z) {
									maxRows = Math.max($(this).find(".inner-md-table:nth(" + z + ") tr").length, maxRows);
									attention |= $(this).find(".inner-md-table:nth(" + z + ") tr.attention-row").length > 0;
									warn |= $(this).find(".inner-md-table:nth(" + z + ") tr.warn-row").length > 0;
									alert |= $(this).find(".inner-md-table:nth(" + z + ") tr.background-light-orange").length > 0;
								}

								var rowSize = maxRows;
								if (rowSize > 1 || attention || warn || alert) {
									for (var i = 0; i < rowSize; ++i) {
										var maxHeight = 0;
										for (var j = 0; j < colSize; ++j) {
											if ($(this).find(".inner-md-table:nth(" + j + ") tr").length === rowSize) {
												maxHeight = Math.max(
													$(this)
														.find(".inner-md-table:nth(" + j + ") tr:nth(" + i + ")")
														.height(),
													maxHeight
												);
											}
										}
										for (var k = 0; k < colSize; ++k) {
											if ($(this).find(".inner-md-table:nth(" + k + ") tr").length === rowSize) {
												$(this)
													.find(".inner-md-table:nth(" + k + ") tr:nth(" + i + ") td")
													.height(maxHeight);
											}
										}
									}
								}
							}
						});
				});
			}, 1000);
		};

		$scope.breadcrumbClick = function (breadcrumb) {
			if (ctrl.options.breadcrumbClick) {
				ctrl.options.breadcrumbClick(breadcrumb);
			}
		};

		$scope.rowClick = function (event, row, column) {
			event.stopPropagation();

			if (column.disableClick && column.disableClick(row)) {
				return;
			}

			if (column.checkbox) {
				$scope.boxClick(event, row, $scope.ctrl.options.data);
			} else if (column.checkboxArray) {
				$scope.boxClick(event, row, $scope.ctrl.options.data);
			} else if ($scope.ctrl.options.rowClick) {
				if (!$scope.canClick()) {
					return;
				}
				$scope.ctrl.options.rowClick(row);
			} else if ($scope.ctrl.options.editState) {
				if (!$scope.canClick()) {
					return;
				}
				$state.go($scope.ctrl.options.editState, { id: row.ID });
			}
		};

		$scope.boxClick = function (event, row, list) {
			$scope.ctrl.options.updateRow(row, list);
		};

		$scope.create = function (event) {
			event.stopPropagation();
			if ($scope.ctrl.options.newState) {
				$state.go($scope.ctrl.options.newState, { id: null });
			} else if ($scope.ctrl.options.editState) {
				$state.go($scope.ctrl.options.editState, { id: null });
			} else if ($scope.ctrl.options.create) {
				$scope.ctrl.options.create();
			}
		};

		$scope.resetDelete = function (clear) {
			$scope.isDeleting = false;
		};

		$scope.clearDelete = function (clear) {
			$scope.resetDelete();

			var compareItems = Vgr.util.compareItems;

			var deletedItems = $scope.ctrl.options.data.filter(function (item) {
				return $scope.ctrl.options.selected.find(function (selected) {
					return compareItems(selected, item);
				});
			});

			for (var index = 0; index < deletedItems.length; index++) {
				var deletedItem = deletedItems[index];
				var deletedIndex = $scope.ctrl.options.data.indexOf(deletedItem);
				$scope.ctrl.options.data.splice(deletedIndex, 1);
			}

			$scope.ctrl.options.selected = $scope.ctrl.options.data.filter(function (item) {
				$scope.ctrl.options.selected.find(function (selected) {
					return compareItems(selected, item);
				});
			});

			if (!$scope.ctrl.options.noConfirmationDelete) {
				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.DELETED_SUCCESSFULLY);
			}
		};

		$scope.delete = function (event) {
			var selected;
			if (event.selected == undefined) {
				event.stopPropagation();
				selected = $scope.ctrl.options.selected;
			} else {
				selected = event.selected;
				$scope.ctrl.options.selected = selected;
			}
			if (typeof selected != "undefined" && selected.length === 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_SELECTED_REGISTER);
			}

			var data = $scope.ctrl.options.data;
			var filteredData = $scope.filteredList;

			var deletedIds = Vgr.util.selectedToDeletedIds(selected, filteredData);

			if (deletedIds.Ids || (selected && selected.length)) {
				$scope.selectedForDelete = selected;
				$scope.dataForDelete = data;
				$scope.filteredDataForDelete = filteredData;

				if ($scope.ctrl.options.deletePopup) {
					var valuesToDelete = getValuesToDelete();
					openDeletePopup(valuesToDelete);
				} else if ($scope.ctrl.options.deleteConfirmation) {
					$scope.ctrl.options.deleteConfirmation(selected);
				} else if ($scope.ctrl.options.noConfirmationDelete) {
					$rootScope.$broadcast(Vgr.constants.evtConfirmYes);
				} else if (!$scope.ctrl.options.deleteMSG) {
					$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, $rootScope.labels.DELETE_CONFIRMATION);
				} else {
					$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, $scope.ctrl.options.deleteMSG);
				}
			}
			return true;
		};

		function getValuesToDelete() {
			if ($scope.ctrl.options.selectAllRegisters) {
				return $scope.ctrl.options.selectAllRegisters.isSelected ? $scope.ctrl.options.data : $scope.selectedForDelete;
			} else {
				return $scope.selectedForDelete;
			}
		}

		function setSelectAllRegisters() {
			if ($scope.ctrl.options.selectAllRegisters) {
				$scope.ctrl.options.selectAllRegisters.isSelected = false;
			}
		}

		function openDeletePopup(valuesToDelete) {
			var params = {
				localVariables: {
					popupTitle: $scope.ctrl.options.deletePopup.title,
					popupText: $scope.ctrl.options.deletePopup.text,
					list: valuesToDelete,
					operationMethod: $scope.ctrl.options.deletePopup.deleteMethod,
					getDto: $scope.ctrl.options.deletePopup.getDto,
					idField: $scope.ctrl.options.deletePopup.idField,
					descriptionField: $scope.ctrl.options.deletePopup.descriptionField,
					cancelLabel: $scope.ctrl.options.deletePopup.cancelLabel
						? $scope.ctrl.options.deletePopup.cancelLabel
						: $rootScope.labels.CANCEL,
					confirmLabel: $rootScope.labels.DELETE,
					keepPopupOpenOnFinish: false
				}
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/operationConfirmationPopup_template.html",
					"OperationConfirmationPopupCtrl",
					null,
					params,
					true
				)
				.then(
					function () {
						onCloseDeletePopup(true);
					},
					function (hasDeleted) {
						onCloseDeletePopup(hasDeleted);
					}
				);
		}

		function onCloseDeletePopup(hasDeleted) {
			setSelectAllRegisters();
			if (hasDeleted) {
				$scope.ctrl.options.deletePopup
					.onClose($scope.selectedForDelete)
					.then(onDeletePopupOnCloseEvent, onDeletePopupOnCloseEvent);
			}
		}

		function onDeletePopupOnCloseEvent() {
			if (!$scope.ctrl.options.deletePopup.noClearDelete) {
				$scope.clearDelete();
			} else {
				$scope.ctrl.options.selected = [];
			}
		}

		$scope.$on(Vgr.constants.evtConfirmYes, function (event) {
			onDeleteConfirmation();
		});

		function onDeleteConfirmation() {
			if (!$scope.ctrl.options.delete || !$scope.selectedForDelete) {
				return;
			}
			$scope.isDeleting = true;
			$scope.ctrl.options
				.delete(
					$scope.selectedForDelete,
					$scope.dataForDelete,
					$scope.filteredDataForDelete,
					$scope.ctrl.options.gridFilter
				)
				.then(function (response) {
					if (response && response.data && !response.data.success && response.data.errorCode) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels[response.data.errorCode]);
					} else {
						$scope.clearDelete();
					}
				}, $scope.resetDelete);
		}

		$scope.bindField = function (item, prop, groupBy) {
			var value = Vgr.util.getDescendantProp(item, prop);
			var key = Vgr.util.getDescendantProp(item, groupBy);

			if (value > map[key] || typeof map[key] === "undefined") {
				map[key] = value;
			}

			Vgr.util.setDescendantProp(item, prop, map[key]);
		};

		$scope.bindFields = function (array, prop, boundValue) {
			array.forEach(function (item) {
				$scope.bindField(item, prop, boundValue);
			});
		};

		$scope.autoCompleteSelected = function (row, column) {
			var groupedColumns = $scope.ctrl.options.columnDefinitions.filter(function (columnDefinition) {
				return (
					columnDefinition.groupBy && columnDefinition.textField && columnDefinition.groupBy.indexOf(column.input) === 0
				);
			});

			groupedColumns.forEach(function (groupedColumn) {
				$scope.bindField(row, groupedColumn.textField, groupedColumn.groupBy);
			});
		};

		$scope.getFormattedField = function (row, column) {
			var value = Vgr.util.getDescendantProp(row, column.field);

			if (column.type === "date") return _convertDateValue(value);
			else if (column.type === "number") return Vgr.util.formatUnitDecimalPlaces(value, column.decimalPlaces);

			return value;
		};

		$scope.getFormattedSubfield = function (row, column) {
			var value = Vgr.util.getDescendantProp(row, column.subfield);

			if (column.type === "date") return _convertDateValue(value);

			return value;
		};

		$scope.getFormattedAny = function (row, any) {
			var value = Vgr.util.getDescendantProp(row, any);
			return value;
		};

		function _convertDateValue(value) {
			if (value) {
				var isDate = moment(value).isValid();
				if (isDate) {
					return Vgr.date.fromDateToString(value);
				}
			}
			return value;
		}

		$scope.hideNewButton = function () {
			if (!$scope.ctrl.options) {
				return true;
			}
			return $scope.ctrl.options.removeNew || !hasPermission(permissionActionTypes.Create);
		};

		$scope.hideDeleteButton = function () {
			if (!$scope.ctrl.options) {
				return true;
			}
			return $scope.ctrl.options.removeDelete || !hasPermission(permissionActionTypes.Delete);
		};

		$scope.canBeFiltered = function (column) {
			return $.inArray(column.field || column.subfield, $scope.ctrl.options.filterFields) > -1;
		};

		$scope.getOrderByColumn = function (column) {
			var columnField = column.orderField || column.field || column.textField || column.input;

			if (!$scope.ctrl.options.data || $scope.ctrl.options.data.length === 0) return columnField;

			if ($scope.ctrl.options.data[0].hasOwnProperty(columnField + "ISO")) {
				return columnField + "ISO";
			} else {
				return columnField;
			}
		};

		$scope.canClick = function () {
			return ($scope.ctrl.options.rowClick || $scope.ctrl.options.editState) && $scope.permissions.view;
		};

		$scope.enableSelect = function () {
			return $scope.ctrl.options.enableSelect && $scope.permissions.view;
		};

		$scope.showCdfButton = function () {
			return $scope.ctrl.options && $scope.ctrl.options.cdf && ($scope.permissions.create || $scope.isSupplier);
		};

		$scope.showCdf = function (ev) {
			if ($scope.ctrl.options.selected.length > 0) {
				$scope.ctrl.options.help.locals.isPopUp = false;
				dialogService.showDialogFromTemplateWithScope(
					ev,
					$scope.ctrl.options.cdf.templateUrl,
					$scope.ctrl.options.cdf.controller,
					$scope
				);
			} else {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels["ERR-EGD-0025"]);
			}
		};

		$scope.setShowCheckBoxes = function () {
			var enableSelect = $scope.enableSelect();
			var showDelete = !$scope.hideDeleteButton();
			if (enableSelect || showDelete) {
				$scope.showCheckBoxes = true;
			} else {
				$scope.showCheckBoxes = null;
			}
		};

		$scope.$watch("ctrl.options.removeDelete", $scope.setShowCheckBoxes);
		$scope.$watch("ctrl.options.enableSelect", $scope.setShowCheckBoxes);

		$scope.importData = function () {
			$scope.ctrl.options.import();
		};

		$scope.getOptionsList = function (item, column) {
			if ($scope.ctrl.options.getInputOptions) {
				return $scope.ctrl.options.getInputOptions(item, column);
			}
			return [];
		};

		$scope.isID = function (fieldDescription) {
			if (fieldDescription && fieldDescription.length)
				return fieldDescription.length - fieldDescription.indexOf("ID") === 2;
			else return false;
		};

		$scope.isNumber = function (str) {
			return false;
		};

		$scope.$watch("ctrl.options.promise", function (newVal) {
			if (newVal && newVal.then) {
				newVal.then(
					function () {
						if (!$scope.ctrl.options.query.serverPagination) {
							$scope.ctrl.options.hasData = true;
						}
					},
					function () {
						$scope.ctrl.options.errorLoading = true;
					}
				);
			}
		});

		$scope.showIcon = function (row, column) {
			var condition;
			if (column != null && column.icon != null && column.icon.condition) condition = column.icon.condition;

			if (condition && row && row[condition]) return column.icon && row[condition];

			if (typeof condition === "function") return condition(row);

			return false;
		};

		$scope.getTagList = function (row, column) {
			var text = $scope.getFormattedField(row, column);

			if (text) {
				var tagList;
				if (Array.isArray(text)) {
					return text;
				} else if (text !== "") {
					var tagList = text.split(", ");
					return tagList;
				}
			}
		};

		$scope.getColumnList = function (row, column) {
			var array = $scope.getFormattedField(row, column);
			return array;
		};

		$scope.showTag = function (tag) {
			return tag;
		};

		$scope.$on(Vgr.constants.evtGridDelete, function () {
			$scope.delete($rootScope.deleteEvt);
		});

		$scope.$on(Vgr.constants.evtGridCreate, function () {
			$scope.create($rootScope.createEvt);
		});

		$scope.$on(Vgr.constants.evtGridCDF, function () {
			$scope.showCdf($rootScope.CDFEvt);
		});

		$scope.$on(Vgr.constants.evtScreenDataRefresh, function () {
			$scope.reloadList();
		});

		$scope.$on(Vgr.constants.evtGridFilter, function () {
			$scope.onCustomPeriodDateFilterButtonClick();
		});

		$scope.$on(Vgr.constants.evtGridFilterOnDate, function () {
			$scope.onDateFilterSelectChange($rootScope.dateType);
		});

		$scope.$on(Vgr.constants.evtGridFilterTag, function () {
			$scope.addTextChipFromNavBar($rootScope.textTagsFilter);
		});

		$scope.$watch(
			function () {
				return $mdMedia("gt-sm");
			},
			function (screenIsGtSm) {
				$scope.screenIsGtSm = screenIsGtSm;
			}
		);

		$scope.deleteThisRow = function (event, row) {
			event.stopPropagation();
			var selected = [];
			selected[0] = row;
			if (typeof selected != "undefined" && selected.length === 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_SELECTED_REGISTER);
			}
			var data = $scope.ctrl.options.data;
			var filteredData = $scope.filteredList;
			var deletedIds = Vgr.util.selectedToDeletedIds(selected, filteredData);
			if (deletedIds.Ids || (selected && selected.length)) {
				$scope.selectedForDelete = selected;
				$scope.dataForDelete = data;
				$scope.filteredDataForDelete = filteredData;

				if ($scope.ctrl.options.deletePopup) {
					openDeletePopup($scope.selectedForDelete);
				} else if ($scope.ctrl.options.deleteConfirmation) {
					$scope.ctrl.options.deleteConfirmation(selected);
				} else if ($scope.ctrl.options.noConfirmationDelete) {
					$rootScope.$broadcast(Vgr.constants.evtConfirmYes);
				} else if (!$scope.ctrl.options.deleteMSG) {
					$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, $rootScope.labels.DELETE_CONFIRMATION);
				} else {
					$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, $scope.ctrl.options.deleteMSG);
				}
			}
		};

		function countEditOptions(editOptions, row) {
			var count = 0;

			for (var i = 0; i < editOptions.length; i++) {
				if (!editOptions[i].disable(row)) {
					count++;
				}
			}

			return count;
		}

		$scope.hasManyActions = function (editOptions, row, column) {
			if ($scope.ctrl.options.isDisabled && $scope.ctrl.options.isDisabled(row, column)) {
				return false;
			}

			return countEditOptions(editOptions, row) > 1;
		};

		$scope.hasOneAction = function (editOptions, row, column) {
			if ($scope.ctrl.options.isDisabled && $scope.ctrl.options.isDisabled(row, column)) {
				return false;
			}

			return countEditOptions(editOptions, row) == 1;
		};

		$scope.getEnabledAction = function (editOptions, row) {
			for (var index in editOptions) {
				if (!editOptions[index].disable(row)) {
					return editOptions[index];
				}
			}

			return null;
		};

		$scope.$watch("ctrl.options.selected.length", function (newValue, oldValue) {
			if ($scope.ctrl.options.onSelectedItens) {
				var anySelected = newValue > 0;
				$scope.ctrl.options.onSelectedItens(anySelected);
			}

			if (isToHideInformationMessage(newValue, oldValue)) {
				$scope.ctrl.options.selectAllRegisters.isSelected = false;
				$scope.showInformationMessage = false;
			}
		});

		function isToHideInformationMessage(newSelectedLength, oldSelectedLength) {
			return (
				$scope.ctrl.options.selectAllRegisters && $scope.showInformationMessage && newSelectedLength < oldSelectedLength
			);
		}

		$scope.onClickHasOneAction = function ($event, row, column) {
			$event.stopPropagation();

			var enabledAction = $scope.getEnabledAction(column.editOptions, row);

			if (enabledAction.type == "delete") {
				$scope.deleteThisRow($event, row);
			} else {
				enabledAction.click(row, column);
			}
		};

		var onHeadCheckboxSelected = $rootScope.$on(
			Vgr.constants.events.table.headCheckboxSelected,
			function (event, tableId) {
				if (tableId == $scope.tableId) {
					if (
						$scope.ctrl.options.selectAllRegisters &&
						$scope.ctrl.options.selected.length < $scope.ctrl.options.data.length
					) {
						$scope.showInformationMessage = true;
						updateSelectAllRegistersMessage();
					}
				}
			}
		);

		var onHeadCheckboxUnSelected = $rootScope.$on(
			Vgr.constants.events.table.headCheckboxUnSelected,
			function (event, tableId) {
				if (tableId == $scope.tableId) {
					if ($scope.ctrl.options.selectAllRegisters) {
						onClearSelectedEntities();
					}
				}
			}
		);

		var evtChangeGridFilters = $rootScope.$on(Vgr.constants.evtChangeGridFilters, function () {
			onClearSelectedEntities();
		});

		function updateSelectAllRegistersMessage() {
			$scope.selectAllRegistersTitle = $scope.ctrl.options.selectAllRegisters.getTitle();
			$scope.selectAllRegistersTextButton = $scope.ctrl.options.selectAllRegisters.getTextButton();
		}

		function onClearSelectedEntities() {
			$scope.ctrl.options.selected = [];
			$scope.showInformationMessage = false;
		}

		$scope.selectedAllEntities = function () {
			$scope.ctrl.options.selectAllRegisters.isSelected = !$scope.ctrl.options.selectAllRegisters.isSelected;

			if ($scope.ctrl.options.selectAllRegisters.isSelected) {
				updateSelectAllRegistersMessage();
			} else {
				onClearSelectedEntities();
			}
		};

		$scope.$on("$destroy", function () {
			// disable the listener
			onHeadCheckboxSelected();
			onHeadCheckboxUnSelected();
			evtChangeGridFilters();
			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
